import gql from 'graphql-tag'

export const CreateRate = gql`
  mutation createRate($input: CreateRateInput!) {
    createRate(input: $input) {
      success
    }
  }
`

export const DeleteFavorite = gql`
  mutation deleteFavorite($input: DeleteFavoriteInput!) {
    deleteFavorite(input: $input) {
      success
    }
  }
`
