import gql from 'graphql-tag'

export const IsUserRated = gql`
  query isUserRated($id: ID!, $rateableType: String!) {
    isUserRated(id: $id, rateableType: $rateableType) {
      isUserRated
      canRate
      rate {
        id
        comment
        rate
        createdAt
      }
    }
  } 
`

export const Rates = gql`
  query rates($id: ID!, $rateableType: String!) {
    rates(id: $id, rateableType: $rateableType) {
      id
      comment
      rate
      createdAt
    }
  } 
`
