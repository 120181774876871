<template>
  <v-card
    class="fill-height"
    flat
    >
    <v-card-title
      class="font-weight-regular px-0"
      >
      <div>
        <div
          class="px-2"
          >
          Opiniones
        </div>
        <v-rating
          background-color="grey lighten-2"
          color="primary"
          half-icon="mdi-star-half"
          half-increments
          readonly
          length="5"
          v-model="product.rating.rating"
          ></v-rating>
        <div
          class="caption px-2 grey--text text--darken-1"
          >
          {{ product.rating.rating }} estrellas sobre {{ product.rating.votes }} valoraciones
        </div>
      </div>
    </v-card-title>
    <v-card-text
      class="px-0"
      >
      <Rate
        ref="rate"
        :product="product"
        @reload="fetchRates"
        />

      <v-divider
        class="mb-4"
        ></v-divider>

      <Rates
        :rates="rates"
        />
    </v-card-text>
  </v-card>
</template>

<script>
import { Rates as RatesQuery } from '@/graphql/queries/rates'
import { CreateRate } from '@/graphql/mutations/rate' 

const Rate = () => import(/* webpackPrefetch: true */ "@/components/products/Rating");
const Rates = () => import(/* webpackPrefetch: true */ "@/components/rates/Rates");

export default {
  name:"ProductDetail",

  data: () => {
    return {
      loading: false,
      rates: [],
      rating: 0,
      quantity: 0
    }
  },

  components: {
    Rate,
    Rates
  },

  props:{
    product:{
      type: Object,
      required: true
    },
    categories: {
      type: Array,
      required: false
    }
  },

  mounted () {
    this.fetchRates()
  },

  methods: {
    fetchRates () {
      this.loading = true
      this.$apollo.query({
        query: RatesQuery,
        variables: {
          rateableType: 'Product',
          id: this.product.id
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.loading = false
        this.$refs.rate.loading = false
        this.rates = res.data.rates
      })
    },
  }
}
</script>

<style lang="sass">
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0
</style>
